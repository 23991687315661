@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=BIZ+UDPMincho:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Ryumin Medium KL,Noto Serif JP", serif;
  font-optical-sizing: auto;
  font-weight: 400 !important;
  font-style: normal;
}

p {
  font-size: 1.2rem;
}

/* 背景 */
.section_red {
  background-color: rgba(255, 0, 0, 0.507);
}
.section_white {
  background-color: rgb(255, 255, 255);
}
.section_bule {
  background-color: rgba(17, 0, 255, 0.507);
}

.container {
  padding: 60px;
}

.showcase {
  /* position: absolute; */
  right: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: #111; */
  color: #fff;
  transition: 0.5s;
}

.showcase.active {
  position: absolute;
  right: 300px;
}

.showcase video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
}

.showcase header {
  position: absolute;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e0000093;
  mix-blend-mode: overlay;
}

.logo_img {
  margin-left: 50px;
}

/* メイン */

.maintitle_text {
  text-align: center;
  line-height: 1.5;
}

/* .pic-container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 600px;
  height: 338px;
  padding: 0 3rem;
  box-sizing: border-box;
  background-color: #fefefe;
} */

.title_box {
  margin-bottom: 50px;
}

.title_text {
  position: relative;
  width: 50%;
  top: 50px;
  left: 45px;
  font-size: 60px;
}

.title_text_3 {
  position: relative;
  width: 60%;
  top: 50px;
  left: 45px;
  font-size: 60px;
}

.title_backtext {
  position: absolute;
  color: #fff;
  font-size: 90px;
}
.title_backtext_red {
  color: red;
  opacity: 0.5;
}

.title_subtext {
  margin-top: 70px;
  margin-left: 5px;
}

/* しる */
.image_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.image_map {
  position: relative !important;
  margin-top: -230px;
  padding: 0 80px;
}

.image_item {
  width: 100%;
  height: 100%;
}

/* みる */
.video_box {
  padding-top: 50px;
}

/* よむ */
.blog_box {
  padding-top: 50px;
}

.blog_datebox {
  padding-top: 25px;
}

.blog_date {
  color: #fff;
}

.blog_title {
  color: #fff;
}

/* つぎへ */
.img_sns {
  padding: 10px;
}

/* ボタン */
.border_btn01 {
  display: inline-block;
  width: 100%;
  /* ボタン幅 */
  max-width: 400px;
  color: #0411ca; /* 文字色 */
  /* 線幅・種類・色 */
  /* border: 2px solid #4d9bc1; */
  background: #fff; /* 背景色 */
  padding: 1em 2em;
  font-weight: bold; /* 文字の太さ */
  text-decoration: none;
  text-align: center;
  transition: 0.3s;
  z-index: 50;
}

/* マウスオーバーした際のデザイン */
.border_btn01:hover {
  color: #fff; /* 文字色 */
  background: #0411ca; /* 背景色 */
  z-index: 50;
}

.hero_btn {
  margin: 500px auto 0;
  width: 80%;
}

/* フッター */
footer {
  background-color: red;
}

.catch {
  color: #fff;
  margin: 10px 0;
}

/* ヘッダ */
header {
  z-index: 100;
}
/* ここから */
header {
  /* padding: 30px 4% 10px; */
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

/* nav {
  margin: 0 0 0 auto;
} */

/* かぶってるので調整 */
/* ul {
  list-style: none;
  margin: 0;
  display: flex;
} */

/* かぶってるので調整 */
/* li {
  margin: 0 0 0 15px;
  font-size: 18px;
} */

/* かぶってるので調整 */
/* .nav_text {
  text-decoration: none;
  color: #fff;
} */

header {
  position: fixed;
  z-index: 5;
  width: 100%;
}

.c-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  width: 100%;
  box-sizing: border-box;
}

.c-hamburger-menu {
  position: relative;
}

.c-hamburger-menu__input {
  display: none;
}

.c-header__list {
  display: flex;
  margin: 0;
  padding: 0;
}

.c-header__list-item {
  list-style: none;
  text-decoration: none;
}

.c-header__list-link {
  color: #fff;
  display: block;
  text-decoration: none;
  margin-right: 20px;
  padding: 10px 0;
}

.c-header__list-link:hover {
  filter: opacity(0.6);
}

@media screen and (max-width: 750px) {
  /* ナビ */
  .c-hamburger-menu__list {
    /* background-color: white; */
    /* opacity: 0.4; */
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    left: 0;
    padding: 2rem;
    position: absolute;
    transform: translateX(-100%);
    transition: 0.3s;
    top: 100%;
    width: 100%;
  }

  #hamburger:checked ~ .c-hamburger-menu__list {
    transform: translateX(0%);
    transition: 0.3s;
  }

  .c-hamburger-menu__input {
    display: none;
  }

  .c-hamburger-menu__bg {
    background-color: black; /* カスタマイズしてください */
    cursor: pointer;
    display: none;
    height: 100vh;
    left: 0;
    opacity: 0.6; /* カスタマイズしてください */
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  #hamburger:checked ~ .c-hamburger-menu__bg {
    display: block;
  }

  .c-hamburger-menu__button {
    display: none;
  }

  /* ハンバーガーメニュー */
  .c-hamburger-menu__button {
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px; /* カスタマイズしてください */
    height: 32px; /* カスタマイズしてください */
    justify-content: center;
    width: 32px; /* カスタマイズしてください */
  }
  .c-hamburger-menu__button-mark {
    background-color: white; /* カスタマイズしてください */
    display: block;
    height: 1px; /* カスタマイズしてください */
    transition: 0.3s; /* カスタマイズしてください */
    width: 20px; /* カスタマイズしてください */
  }

  #hamburger:checked
    ~ .c-hamburger-menu__button
    .c-hamburger-menu__button-mark:nth-of-type(1) {
    transform: translate(2px, 1px) rotate(45deg); /* カスタマイズしてください */
    transform-origin: 0%; /* カスタマイズしてください */
  }
  #hamburger:checked
    ~ .c-hamburger-menu__button
    .c-hamburger-menu__button-mark:nth-of-type(2) {
    opacity: 0;
  }
  #hamburger:checked
    ~ .c-hamburger-menu__button
    .c-hamburger-menu__button-mark:nth-of-type(3) {
    transform: translate(2px, 3px) rotate(-45deg); /* カスタマイズしてください */
    transform-origin: 0%; /* カスタマイズしてください */
  }

  .container {
    padding: 30px;
  }
  p {
    font-size: 0.8rem;
  }

  .logo_img {
    margin-left: 0px;
  }

  .title_box {
    margin-bottom: 30px;
  }

  .title_text {
    position: relative;
    width: 50%;
    top: 50px;
    left: 45px;
    font-size: 60px;
  }

  .title_subtext {
    margin-top: 60px;
  }

  /* しる */
  .image_map {
    margin-top: -150px;
    margin-bottom: 40px;
    padding: 0 80px;
    width: 100% !important;
  }

  /* よむ */
  .blog_box {
    padding-top: 30px;
  }
  /* 記事2 */
  .blog_boxsm {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
}
